import React, { useState } from 'react';
import { DateTime } from 'luxon';
import './DailyOverview.scss';
import Page from '../Page';
import I18n from '../../lib/I18n';
import { DndContext, DragEndEvent, DragMoveEvent, DragOverlay, DragStartEvent, getClientRect, MeasuringConfiguration, MeasuringStrategy, pointerWithin } from '@dnd-kit/core';
import ContainerVehicleApi from '../../api/container-vehicle/ContainerVehicleApi';
import VehiclePlanningApi from '../../api/vehicle/VehiclePlanningApi';
import VehicleApi from '../../api/vehicle/VehicleApi';
import NeedManagementApi from '../../api/need/NeedManagementApi';
import RadioCodeApi from '../../api/radio/RadioCodeApi';
import MonthlyPlanningApi from '../../api/planning/MonthlyPlanningApi';
import WorkersRegistryUtils from '../WorkersRegistry/WorkerUtils';
import DailyPlanningApi from '../../api/planning/DailyPlanningApi';
import ShiftRegistryApi from '../../api/shift/ShiftRegistryApi';
import WorkerRegistryApi from '../../api/worker/WorkerRegistryApi';
import { WorkerDto } from '../../api/entity/WorkerDto';
import GfDateUtils from '../../components/DatePicker/GfDateUtils';
import { NeedDto } from '../../api/entity/NeedDto';
import ContainerVehicleRow from './ContainerVehicle/ContainerVehicleRow';
import ContainerVehicleList from './ContainerVehicle/ContainerVehicleList';
import SideBar from './SideBar/SideBar';
import Modal from 'react-modal';

import { restrictToVerticalAxis, restrictToWindowEdges, restrictToFirstScrollableAncestor, snapCenterToCursor } from '@dnd-kit/modifiers';
import { IWorkers, splitWorkersByServices, getWorkerStartEndForShiftInContainerVehicle, isMattinaWithShiftMap, isPomeriggioWithShiftMap, isSeraWithShiftMap, isNotteWithShiftMap, getShiftStartEnd, distictValues, getServiceToRecord } from './DailyOverviewUtils';
import Select from '../../components/Select/Select';
import Button from '../../components/Button/Button';
import TimeLineNavigation from '../../components/TimeLine/TimeLineNavigation/TimeLineNavigation';
import DailyPlanningNavigationLabel from '../DailyPlanning/DailyNavigationBar/DailyPlanningNavigationLabel';
import { CircuitSelect } from '../../components/CircuitSelect/CircuitSelect';
import { Pages } from '../../router/Pages';
import DailyDndContext from './DailyDndContext';
interface State {
  activeId: string | null;
  day: DateTime;
  dateIndex: number;
  //allWorkers: WorkerDto[];
  radioCodeMap: Map<number, string>;
  vehiclesMap: Map<number, any>;
  dailyWorkers: any[];
  activeWorkers: WorkerDto[];
  needs: NeedDto[];
  shifts: any[];
  allVehicles: any[];
  dailyVehicles: any[];
  //monthlyPlanning: any[],
  containerServiceVehicle: any[];
  dailyWorkersStartingOnDate: any[];
  dailyVehiclesStartingOnDate: any[];
  dailyContainerVehicleStartingOnDate: any[];
  radioCodeIds: any[];
  needVehicles: any[];

  workersMap: Map<string, WorkerDto>;
  shiftMap: Map<string, any>;
  needMap: Map<string, any>;
  dragging?: boolean;
  loading?: boolean;

  // searchFilter: string,
  // circuitFilter: string,
  // dailyContent: UserDailyShiftDto[],
  // reperibleWorkerMap: Map<string, DailyPlanningWorkerDtoNt[]>,
  // reperibleWorkers: DailyPlanningWorkerDtoNt[],
  // coursesWorkerMap: Map<string, DailyPlanningWorkerDtoNt[]>,
  // coursesWorkers: DailyPlanningWorkerDtoNt[],
  // tableContentMapping: WTEntry[],
  // searchValue?: string,
  // cancelSearchSignal?: number,
  // pendingDownload?: boolean,
  // workers: WorkerDto[]

  fullData: boolean;
  workersByService: IWorkers;
  moveWorkerToContainerVehicleModalIsOpened: boolean;
  workerToMove?: any;
  containerVehicleToMoveTo?: string;
  serviceToMoveTo?: string;
  turnToApply?: string;
  modalProposeNewShifts?: any[];
  modalDuplicateWorker: boolean;
  shiftIsPressed?: boolean;
  circuitFilter: string;
  circuitFilterDisabled?: boolean;
  reducedHeight: boolean;
}

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 10000 },
};

export default class DailyOverview extends Page<{}, State> {
  private containerVehicleApi = new ContainerVehicleApi();
  private dailyPlanningApi = new DailyPlanningApi();
  // private workerRegistryUtils = new WorkersRegistryUtils();
  // private monthlyPlanningApi = new MonthlyPlanningApi();
  private radioCodeApi = new RadioCodeApi();
  private needApi = new NeedManagementApi();

  private vehicleApi = new VehicleApi();
  // private vehicleApiUnavailability = new VehicleUnavailabilityApi();
  private vehiclePlanningApi = new VehiclePlanningApi();


  downHandler({key}) {
    if (key === 'Shift') {
      this.setState({ shiftIsPressed: true });
      console.log('shift pressed');
    }
  }

  upHandler({key}) {
    if (key === 'Shift') {
      this.setState({ shiftIsPressed: false });
      console.log('shift released');
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.downHandler.bind(this));
    window.addEventListener('keyup', this.upHandler.bind(this));
    window.addEventListener('resize', this.handleResize.bind(this));
    this.setViewportScale();
    this.handleResize();
  }

  handleResize() {
    if (window.outerHeight < 1000) {
      this.setState({ reducedHeight: true });
    } else {
      this.setState({ reducedHeight: false });
    }
  };

  //set meta viewport to scale 0.7 if screen width is less than 1200px
  setViewportScale() {
    const viewport = document.querySelector('meta[name="viewport"]');
    if (viewport) {
      if (window.innerWidth < 1200) {
        viewport.setAttribute('content', 'width=device-width, initial-scale=0.6, maximum-scale=2, minimum-scale=0.6, user-scalable=yes');
      } else {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1');
      }
    }
  }

  resetViewportScale() {
    const viewport = document.querySelector('meta[name="viewport"]');
    if (viewport) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1');
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.downHandler);
    window.removeEventListener('keyup', this.upHandler);
    window.removeEventListener('resize', this.handleResize);
    this.resetViewportScale();
  }

  public constructor(props: any) {
    super(props);
    var date = DateTime.now();
    if (props.match.params.date) {
      date = DateTime.fromFormat(props.match.params.date, GfDateUtils.STORED_DATE_FORMAT);
    }

    const day = date;

    this.state = {
      activeId: null,
      day: day, //DateTime.fromFormat("2022-01-28", GfDateUtils.STORED_DATE_FORMAT), //DateTime.now(), //
      dateIndex: 0,
      //allWorkers: [],
      radioCodeMap: new Map<number, string>(),
      vehiclesMap: new Map<number, any>(),
      dailyWorkers: [],
      activeWorkers: [],
      needs: [],
      shifts: [],
      allVehicles: [],
      dailyVehicles: [],
      //monthlyPlanning: [],
      containerServiceVehicle: [],
      dailyWorkersStartingOnDate: [],
      dailyVehiclesStartingOnDate: [],
      dailyContainerVehicleStartingOnDate: [],
      radioCodeIds: [],
      needVehicles: [],
      workersMap: new Map<string, WorkerDto>(),
      shiftMap: new Map<string, any>(),
      needMap: new Map<string, any>(),
      fullData: false,
      workersByService: {},
      moveWorkerToContainerVehicleModalIsOpened: false,
      modalDuplicateWorker: false,
      circuitFilter: '',
      reducedHeight: false,
    };

    this.loadDataForDay(day);
    //this.loadAllWorkers();
  }


  //effect that sets reducedHeight to true if screen width is less than 800px

  isMattina = (workerOrContainerVehicle: any, containerVehicle: any = null, vehicleNeed: any = null) => {
    return isMattinaWithShiftMap(this.state.shiftMap,  workerOrContainerVehicle, containerVehicle, vehicleNeed);
  }

  isPomeriggio = (workerOrContainerVehicle: any, containerVehicle: any = null, vehicleNeed: any = null) => {
    return isPomeriggioWithShiftMap(this.state.shiftMap,  workerOrContainerVehicle, containerVehicle, vehicleNeed);
  }

  isSera = (workerOrContainerVehicle: any, containerVehicle: any = null, vehicleNeed: any = null) => {
    return isSeraWithShiftMap(this.state.shiftMap,  workerOrContainerVehicle, containerVehicle, vehicleNeed);
  }

  isNotte = (workerOrContainerVehicle: any, containerVehicle: any = null, vehicleNeed: any = null) => {
    return isNotteWithShiftMap(this.state.shiftMap,  workerOrContainerVehicle, containerVehicle, vehicleNeed);
  }


  render() {
    const isElimina = this.state.serviceToMoveTo == "ELIMINA";
    function thisonDayChanged(date: DateTime): void {
      throw new Error('Function not implemented.');
    }



    return (
      <React.Fragment>
      {this.state.loading && <div className="progress-bar fixed-top">
        <div className="progress-bar-value"></div>
      </div>}
      <DailyDndContext
        onDragMove={this.handleDragMove.bind(this)}
        onDragEnd={this.handleDragEnd.bind(this)}
        onDragStart={this.handleDragStart.bind(this)}
        onDragCancel={this.handleDragCancel.bind(this)}
      >

          <div className="canvas">
            <div className="toolbar">
              <div className="circuit">
                            <CircuitSelect className={""} onChange={(value) => {
                              this.onCircuitChange(value)
                            }} value={this.state.circuitFilter} disabled={this.state.circuitFilterDisabled}
                            />
                          </div>
              <TimeLineNavigation
                  navigationLabel={<DailyPlanningNavigationLabel
                  currentDate={this.state.day} />}
                  selectedDate={this.state.day}
                  onDateChange={date => this.onDayChanged(date)}
                  prevPage={()=>this.prevDay()}
                  nextPage={()=>this.nextDay()}
                  granularity="day"
                />
            </div>

            {this.state.fullData && (      <ContainerVehicleList
              containerVehicles={this.state.dailyContainerVehicleStartingOnDate ?? []}
              dailyWorkers={this.state.dailyWorkersStartingOnDate ?? []}
              dailyVehicles={this.state.dailyVehiclesStartingOnDate ?? []}
              vehiclesMap={this.state.vehiclesMap}
              radioCodeMap={this.state.radioCodeMap}
              workersMap={this.state.workersMap}
              shiftMap={this.state.shiftMap}
              workersByService={this.state.workersByService}
              needMap={this.state.needMap}
              circuitFilter={this.state.circuitFilter}
              onServiceSelect={(service) => {
                this.onServiceSelect(service);
              }}
            />)}


            {this.state.fullData && <SideBar
              containerVehicles={this.state.dailyContainerVehicleStartingOnDate}
              dailyWorkers={this.state.dailyWorkersStartingOnDate}
              dailyVehicles={this.state.dailyVehiclesStartingOnDate}
              vehiclesMap={this.state.vehiclesMap}
              radioCodeMap={this.state.radioCodeMap}
              workersMap={this.state.workersMap}
              shiftMap={this.state.shiftMap}
              dragging={this.state.activeId != null}
              workersByService={this.state.workersByService}
              circuitFilter={this.state.circuitFilter}
              reducedHeight={this.state.reducedHeight}
            /> }

          </div>
        <Modal
          isOpen={this.state.moveWorkerToContainerVehicleModalIsOpened}
          // onAfterOpen={afterOpenModal}
          onRequestClose={this.cancelModal.bind(this)}
          style={modalStyle}
          contentLabel=""
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
        <button onClick={closeModal}>close</button> */}
          <div className="modal-content">
            {isElimina && <h2>Conferma eliminazione</h2> }
            {isElimina && <p>Se non ci sono più turni associati al lavoratore, questo verrà impostato come libero</p> }
            {!isElimina && <h2>Seleziona il turno da applicare</h2> }
            {!isElimina && <div className="modal-row">
                {/* selector for chosting turn from modalProposeNewShifts */}
                <Select items={this.state.modalProposeNewShifts ?? ['']} label="Turno: "
                defaultValue={(this.state.modalProposeNewShifts ?? ['']).indexOf(this.state.turnToApply) ?? 0}
                  onChange={(e) => this.setState({ turnToApply: (this.state.modalProposeNewShifts ?? [''])[e] })}
                />
              </div> }
              {/* checkbox for duplicating workers */}

            {!isElimina && <div className="modal-row">
                <label className="TextInput-label">
                  <span>Duplica?</span>
                  <div>
                  <input type="checkbox" checked={this.state.modalDuplicateWorker} onChange={e => this.setState({ modalDuplicateWorker: e.target.checked })} />
                  </div>
                </label>
              </div>}

            <div className="modal-row buttons">
              <Button secondary={true} className="ConfirmModal-CancelButton" onClick={this.cancelModal.bind(this)}>
                Annulla
              </Button>
              <Button className="ConfirmModal-OkButton" onClick={this.applyModal.bind(this)}>
                Conferma
              </Button>
            </div>
          </div>
        </Modal>
      </DailyDndContext>
      </React.Fragment>
    );
  }
  onCircuitChange(value: string) {
    if (value?.toLowerCase() == CircuitSelect.NO_FILTER.toLowerCase()) {
      value = null;
    }

    this.setState({ circuitFilter: value });
  }

  nextDay() {
    //this.loadDataForDay(this.state.day.plus({ days: 1 }));
    this.gotoDate(this.state.day.plus({ days: 1 }));
  }
  prevDay() {
    //this.loadDataForDay(this.state.day.minus({ days: 1 }));
    this.gotoDate(this.state.day.minus({ days: 1 }));
  }
  onDayChanged(date: DateTime) {
    //this.loadDataForDay(date);
    this.gotoDate(date);
  }

  applyModal() {
    var turnToApply = this.state.turnToApply ?? this.state.modalProposeNewShifts[0];
    if (this.state.serviceToMoveTo || this.state.containerVehicleToMoveTo) {
      var turnToApply = this.state.turnToApply ?? this.state.modalProposeNewShifts[0];

      this.closeModal(() => {
        if (this.state.containerVehicleToMoveTo) {
          this.moveWorkerToVehicle(this.state.workerToMove, this.state.containerVehicleToMoveTo, turnToApply, this.state.modalDuplicateWorker);
        }
        else if (this.state.serviceToMoveTo) {
          this.moveWorkerToService(this.state.workerToMove, this.state.serviceToMoveTo, turnToApply, this.state.modalDuplicateWorker);
        }
      });
    }
  }

  cancelModal() {
    this.setState({
      moveWorkerToContainerVehicleModalIsOpened: false,
      turnToApply: null,
      modalProposeNewShifts: [],
      workerToMove: null,
      containerVehicleToMoveTo: null,
      serviceToMoveTo: null,
      modalDuplicateWorker: false,
    });
  }

  closeModal(callback: () => void = () => {}) {
    this.setState({
      moveWorkerToContainerVehicleModalIsOpened: false,
      turnToApply: null,
      modalProposeNewShifts: [],
     }, callback);
  }

  handleDragStart(event: DragStartEvent) {
    console.log('drag start', event);
    this.setActiveId(event.active.id.toString());
  }

  handleDragCancel(event: DragEndEvent) {
    console.log('drag cancel', event);
  }

  setActiveId(id: string | null) {
    this.setState({ activeId: id });
  }

  handleDragMove(event: DragMoveEvent) {}

  cloneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  askShiftForWorkerToVehicle(dailyWorker: any, containerVehicle: any, hintForShift: string) {
    var proposedShifts = [];
    var currentShift = dailyWorker.turn;

    var notOtherShifts = this.state.shifts.filter(x => x.shiftType != "OTHERS");
    var otherShifts = this.state.shifts.filter(x => x.shiftType == "OTHERS").map(x => x.shiftCode);


    if (hintForShift == "M") {
      var onlyMattina =notOtherShifts.filter((x) => this.isMattina(x) && !this.isPomeriggio(x) && !this.isSera(x) && !this.isNotte(x)).map((x) => x.shiftCode);
      var mattina = notOtherShifts.filter((x) => this.isMattina(x) && !onlyMattina.includes(x.shiftCode)).map((x) => x.shiftCode);
      proposedShifts = onlyMattina.concat(mattina);
    }
    if (hintForShift == "P") {
      var onlyPomeriggio = notOtherShifts.filter((x) => this.isPomeriggio(x) && !this.isMattina(x) && !this.isSera(x) && !this.isNotte(x)).map((x) => x.shiftCode);
      var pomeriggio = notOtherShifts.filter((x) => this.isPomeriggio(x) && !onlyPomeriggio.includes(x.shiftCode)).map((x) => x.shiftCode);
      proposedShifts = onlyPomeriggio.concat(pomeriggio);
    }
    if (hintForShift == "S") {
      var onlySera = notOtherShifts.filter((x) => this.isSera(x) && !this.isMattina(x) && !this.isPomeriggio(x) && this.isNotte(x)).map((x) => x.shiftCode);
      var sera = notOtherShifts.filter((x) => this.isSera(x) && !onlySera.includes(x.shiftCode)).map((x) => x.shiftCode);
      proposedShifts = onlySera.concat(sera);
    }
    if (hintForShift == "N") {
      var onlyNotte = notOtherShifts.filter((x) => this.isNotte(x) && !this.isMattina(x) && !this.isPomeriggio(x) && !this.isSera(x)).map((x) => x.shiftCode);
      var notte = notOtherShifts.filter((x) => this.isNotte(x) && !onlyNotte.includes(x.shiftCode)).map((x) => x.shiftCode);
      proposedShifts = onlyNotte.concat(notte);
    }



    //if proposed shift includes current shift, then we put it first
    if (proposedShifts.includes(currentShift)) {
      proposedShifts = [currentShift].concat(proposedShifts.filter(x => x != currentShift));
    }
    else if (dailyWorker.plannedTurn && proposedShifts.includes(dailyWorker.plannedTurn)) {
      proposedShifts = [dailyWorker.plannedTurn].concat(proposedShifts.filter(x => x != dailyWorker.plannedTurn));
    }

    var otherShifts = this.state.shifts.filter(x => !proposedShifts.includes(x.shiftCode)).map(x => x.shiftCode);
    //if other shifts include current shift, then we put it first
    if (otherShifts.includes(currentShift)) {
      otherShifts = [currentShift].concat(otherShifts.filter(x => x != currentShift));
    }

    proposedShifts = proposedShifts.concat(otherShifts);
    proposedShifts = distictValues(proposedShifts);

    var shiftKeyIsPressed = this.state.shiftIsPressed;

    this.setState({
      turnToApply: null,
      moveWorkerToContainerVehicleModalIsOpened: true,
      workerToMove: dailyWorker,
      containerVehicleToMoveTo: containerVehicle,
      modalProposeNewShifts: proposedShifts,
      serviceToMoveTo: null,
      modalDuplicateWorker: shiftKeyIsPressed
    });
  }

  askShiftForWorkerToService(dailyWorker: any, service: string, hintForShift: string) {
    var proposedShifts = [];
    var currentShift = dailyWorker.turn;
    var notOtherShifts = this.state.shifts.filter(x => x.shiftType != "OTHERS");
    var otherShifts = this.state.shifts.filter(x => x.shiftType == "OTHERS").map(x => x.shiftCode);

    if (hintForShift == "M") {
      var onlyMattina = notOtherShifts.filter((x) => this.isMattina(x) && !this.isPomeriggio(x) && !this.isSera(x) && !this.isNotte(x)).map((x) => x.shiftCode);
      var mattina = notOtherShifts.filter((x) => this.isMattina(x) && !onlyMattina.includes(x.shiftCode)).map((x) => x.shiftCode);
      proposedShifts = onlyMattina.concat(mattina);
    }
    if (hintForShift == "P") {
      var onlyPomeriggio = notOtherShifts.filter((x) => this.isPomeriggio(x) && !this.isMattina(x) && !this.isSera(x) && !this.isNotte(x)).map((x) => x.shiftCode);
      var pomeriggio = notOtherShifts.filter((x) => this.isPomeriggio(x) && !onlyPomeriggio.includes(x.shiftCode)).map((x) => x.shiftCode);
      proposedShifts = onlyPomeriggio.concat(pomeriggio);
    }
    if (hintForShift == "S") {
      var onlySera = notOtherShifts.filter((x) =>this.isSera(x) && !this.isMattina(x) && !this.isPomeriggio(x) && !this.isNotte(x)).map((x) => x.shiftCode);
      var sera = notOtherShifts.filter((x) => this.isSera(x) && !onlySera.includes(x.shiftCode)).map((x) => x.shiftCode);
      proposedShifts = onlySera.concat(sera);
    }
    if (hintForShift == "N") {
      var onlyNotte = notOtherShifts.filter((x) => this.isNotte(x) && !this.isMattina(x) && !this.isPomeriggio(x) && !this.isSera(x)).map((x) => x.shiftCode);
      var notte = notOtherShifts.filter((x) => this.isNotte(x) && !onlyNotte.includes(x.shiftCode)).map((x) => x.shiftCode);
      proposedShifts = onlyNotte.concat(notte);
    }

    if (service == 'REPERIBILE') {
      proposedShifts = [ ...this.state.shifts.filter(x =>
          x.shiftCode?.toLowerCase().startsWith("rep")
          || x.shiftCode?.startsWith("R/")
          || x.shiftCode?.endsWith("/R")
      ).map(x => x.shiftCode),
      ...proposedShifts];
    }

    if (service == 'MALATTIA') {
      proposedShifts = [
        ((this.state.shifts.find(x => x.shiftCode == "AR")?.shiftCode) ?? "AR"),
        ...proposedShifts];
    }

    if (service == "RIPOSO") {
      proposedShifts = [
        this.state.shifts.find(x => x.shiftCode == "R" || x.shiftCode == "RN" )?.shiftCode,
        ...proposedShifts];
    }

    if (service == "SMONTO") {
      proposedShifts = [
        this.state.shifts.find(x => x.shiftCode == "S")?.shiftCode,
        ...proposedShifts];
    }

    if (service == "FERIE") {
      proposedShifts = [
        this.state.shifts.find(x => x.shiftCode == "F")?.shiftCode,
        ...proposedShifts];
    }

    if (service == "EXTRA") {
      proposedShifts = [
        this.state.shifts.find(x => x.shiftCode == "FT")?.shiftCode ?? "FT",
        this.state.shifts.find(x => x.shiftCode == "GR")?.shiftCode ?? "GR",
        ...proposedShifts];
    }

    if (service == "GESTIONE") {
      proposedShifts = [
       ( this.state.shifts.find(x => x.shiftCode == "GE")?.shiftCode ?? "GE"),
      ...proposedShifts];
    }

    if (service == "LIBERO") {
      proposedShifts = [
        ...proposedShifts,
        this.state.shifts.find(x => x.shiftCode == "FT")?.shiftCode
      ];
    }

    //if proposed shift includes current shift, then we put it first
    if (proposedShifts.includes(currentShift)) {
      proposedShifts = [currentShift].concat(proposedShifts.filter(x => x != currentShift));
    }
    else if (dailyWorker.plannedTurn && proposedShifts.includes(dailyWorker.plannedTurn)) {
      proposedShifts = [dailyWorker.plannedTurn].concat(proposedShifts.filter(x => x != dailyWorker.plannedTurn));
    }


    var otherShifts = this.state.shifts.filter(x => !proposedShifts.includes(x.shiftCode)).map(x => x.shiftCode);
    //if other shifts include current shift, then we put it first
    if (otherShifts.includes(currentShift)) {
      otherShifts = [currentShift].concat(otherShifts.filter(x => x != currentShift));
    }

    proposedShifts = proposedShifts.concat(otherShifts);
    proposedShifts = distictValues(proposedShifts);

    var shiftKeyIsPressed = this.state.shiftIsPressed;

    this.setState({
      turnToApply: null,
      moveWorkerToContainerVehicleModalIsOpened: true,
      workerToMove: dailyWorker,
      containerVehicleToMoveTo: null,
      modalProposeNewShifts: proposedShifts,
      serviceToMoveTo: service,
      modalDuplicateWorker: shiftKeyIsPressed
    });
  }

  static refCount = 0;
  async moveWorkerToVehicle(dailyWorker: any, containerVehicle: any, newShift: string,  duplicateWorker: boolean, newService:string = null) {


    if (!dailyWorker.plannedTurn) {
      dailyWorker.plannedTurn = dailyWorker.turn;
    }

    var newWorker = this.cloneObject(dailyWorker);

    if (typeof containerVehicle == 'string') {
      containerVehicle = {
        containervehicle: containerVehicle,
        service: newService,
      }
    }


    newWorker.containervehicle = containerVehicle.containervehicle;
    newWorker.radiocodeid = containerVehicle.radiocodeid;
    newWorker.servicecategory = containerVehicle.category;
    if (!containerVehicle.radiocodeid) {
      newWorker.radiocodeid = 0;
    }
    newWorker.service = containerVehicle.service;
    newWorker.turn = newShift;



    newWorker = this.fixWorkerStartEnd(newWorker);

    var soc = containerVehicle.containervehicle;
    if (soc == "-") {
      soc = containerVehicle.service;
    }

    newWorker.service = getServiceToRecord(soc, newWorker.turn);

    if (duplicateWorker) {
      newWorker.id = - (DailyOverview.refCount++);
    }

    var dailyWorkers = this.state.dailyWorkersStartingOnDate.filter(x => x.id != newWorker.id).concat(newWorker);
    this.recalculateWorkers(true, dailyWorkers, this.state.activeWorkers, this.state.shiftMap, true);
    /*
    DailyPlanningWorkerDto

    id: number;
  date: string;
  companionid: number;
  workerid: number;
  workername: string;
  containervehicle: string;
  shiftstart: string;
  shiftend: string;
  servicestart: string;
  serviceend: string;
  service: string;
  turn: string;
  vehicle: string;
  servicecategory: string;
  manuallyCreated: boolean;
  radiocodeid: number;
    */
    var workerDto =  {
      id: newWorker.id,
      date: newWorker.date,
      companionid: newWorker.companionid,
      workerid: newWorker.workerid,
      workername: newWorker.workername,
      containervehicle: newWorker.containervehicle,
      shiftstart: newWorker.shiftstart,
      shiftend: newWorker.shiftend,
      servicestart: newWorker.servicestart,
      serviceend: newWorker.serviceend,
      service: newWorker.service,
      turn: newWorker.turn,
      vehicle: newWorker.vehicle,
      servicecategory: newWorker.servicecategory,
      manuallyCreated: duplicateWorker === true || newWorker.manuallyCreated,
      radiocodeid: newWorker.radiocodeid,
      plannedTurn: newWorker.plannedTurn,
    }


    if (duplicateWorker) {
      await this.dailyPlanningApi.create(workerDto);
    }
    else {
      await this.dailyPlanningApi.update(workerDto);
    }



    this.loadDataForDay(this.state.day, true);
  }

  async dropWorker(dailyWorker: any) {

    var dailyWorkers = this.state.dailyWorkersStartingOnDate.filter(x => x.id != dailyWorker.id);
    var moveToLibero = false;
    var drop = true;
    if (!dailyWorkers.find(x => x.workerid == dailyWorker.workerid)) {
      moveToLibero = true;
      drop = false;
    }

    if (moveToLibero) {
      await this.moveWorkerToService(dailyWorker, "LIBERO", "FT", false);
    }
    else if (drop) {
      this.recalculateWorkers(true, dailyWorkers);
      this.setState({ loading: true });
      await this.dailyPlanningApi.delete(dailyWorker.id);
      this.loadDataForDay(this.state.day, true);
    }
  }

  moveWorkerToService(dailyWorker: any, service: string, turnToApply: string, duplicateWorker: boolean) {
    if (service == 'ELIMINA') {
      this.dropWorker(dailyWorker);
      return;
    }

    var newContainerVehicle = service;
    if (service == 'MALATTIA') {
      newContainerVehicle = '-';
      if (turnToApply != 'AR') { //LO METTO IN GESTIONE
        service = 'GE';
      }
      else
      {
        service = "AR"
      }
    }
    else if (service == 'EXTRA') {
      if (turnToApply == 'FT') {
        newContainerVehicle = '-';
        service = 'LIBERO';
      }
      else {
        newContainerVehicle = '-';
        service = 'GR';
      }
    }
    else if (service == 'LIBERO') {
      newContainerVehicle = '-';
    }
    else if (service == 'GESTIONE') {
      newContainerVehicle = '-';
      service = 'GE';
    }

    this.moveWorkerToVehicle(dailyWorker, newContainerVehicle, turnToApply, duplicateWorker, service);
  }

  fixWorkerStartEnd(dailyWorker: any) {
    var [start, end] = this.getWorkerStartEnd(dailyWorker.turn, dailyWorker.containervehicle);
    // dailyWorker.servicestart = start;
    // dailyWorker.serviceend = end;

    var shiftObj = this.state.shiftMap.get(dailyWorker.turn);
    if (shiftObj) {
      var turnStartEnd = getShiftStartEnd(this.state.shiftMap.get(dailyWorker.turn), true);
      dailyWorker.shiftstart = turnStartEnd[0];
      dailyWorker.shiftend = turnStartEnd[1];
    }
    else {
      dailyWorker.shiftstart = "00:00";
      dailyWorker.shiftend = "23:59";
    }

    var containerVehicle = this.state.dailyContainerVehicleStartingOnDate.find(x => x.containervehicle == dailyWorker.containervehicle);
    if (containerVehicle) {
      dailyWorker.servicestart = containerVehicle.startshift;
      dailyWorker.serviceend = containerVehicle.endshift;
    }

    return dailyWorker;
  }

  fixDailyWorkersStartEnd(
    dailyWorkers: any[],
    shiftMap: Map<string, any> = this.state.shiftMap,
    dailyContainerVehicleStartingOnDate: any[] = this.state.dailyContainerVehicleStartingOnDate,
  ) {
    if (dailyWorkers.length == 0) {
      return dailyWorkers;
    }

    if (dailyContainerVehicleStartingOnDate.length == 0) {
      return dailyWorkers;
    }

    if (shiftMap.size == 0) {
      return dailyWorkers;
    }

    var dailyWorkersFixed = dailyWorkers.map(w => {
      var shiftObj = shiftMap.get(w.turn);
      var containerObj = dailyContainerVehicleStartingOnDate.find(x => x.containervehicle == w.containervehicle);
      if (shiftObj && containerObj) {
        var [start, end] = getWorkerStartEndForShiftInContainerVehicle(shiftObj, null, true);
        // w.servicestart = start;
        // w.serviceend = end;
        w.shiftstart = start;
        w.shiftend = end;
      }

      return w;
    });

    return dailyWorkersFixed;
  }

  getWorkerStartEnd(shift: string, containerVehicle: string) {
    var shiftObj = this.state.shiftMap.get(shift);
    var containerObj = this.state.dailyContainerVehicleStartingOnDate.find(x => x.containervehicle == containerVehicle);
    if (!shiftObj) {
      return [ "00:00", "23:59" ];
    }
    var [start, end] = getWorkerStartEndForShiftInContainerVehicle(shiftObj, containerObj, true);

    return [start, end];
  }

  onServiceSelect(service:string) {
    if (service)
    {
      var date = this.state.day;
      var dateText = date.toFormat(GfDateUtils.STORED_DATE_FORMAT);

      // var url = Pages.DAILY_PLANNING + "/" + dateText + "/" + service;
      // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      // if (newWindow) newWindow.opener = null

      this.props.history.push(Pages.DAILY_PLANNING + "/" + dateText + "/" + service);

    }
  }

  gotoDate(date: DateTime) {
    var dateText = date.toFormat(GfDateUtils.STORED_DATE_FORMAT);
    this.props.history.push(Pages.DAILY_OVERVIEW + "/" + dateText);
    this.loadDataForDay(date);
  }





  recalculateWorkers(update = true, dailyWorkersStartingOnDate = this.state.dailyWorkersStartingOnDate, activeWorkers = this.state.activeWorkers, shiftMap = this.state.shiftMap, loading = false) {
    let dailyWorkers = this.fixDailyWorkersStartEnd(this.fixDailyWorkersNames(dailyWorkersStartingOnDate, activeWorkers), shiftMap, this.state.dailyContainerVehicleStartingOnDate);

    dailyWorkers.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

    let workersByService: IWorkers = {};
    if (dailyWorkers.length > 0) {
      workersByService = splitWorkersByServices(dailyWorkers);

      var cvs = this.state.containerServiceVehicle.map(x => x.containervehicle);


      var missing = workersByService['OTHERS'].filter(x => !cvs.includes(x.containervehicle));
      if (missing.length > 0) {
        workersByService['GESTIONE'] = workersByService['GESTIONE'].concat(missing);
      }
    }

    if (update) {
      this.setState({
        loading,
        dailyWorkersStartingOnDate: dailyWorkers,
        workersByService,
      });
    }


    return { dailyWorkers, workersByService };
  }

  handleDragEnd({ over, active }) {
    this.setActiveId(null);

    if (!over?.id || !active?.id) {
      return;
    }

    var dailyWorkerId = active.id;
    var destinationId = over.id;
    var destinationType = '?';
    var destinationHint = '';

    if (dailyWorkerId?.includes('|')) {
      dailyWorkerId = dailyWorkerId.split('|')[0];
    }
    var dailyWorker = this.state.dailyWorkersStartingOnDate.find(x => x.id == dailyWorkerId);

    if (destinationId?.includes('|')) {
      var parts = destinationId.split('|');
      destinationId = parts[0];
      destinationType = parts[1];
      if (parts.length > 2) {
        destinationHint = parts[2];
      }
      else if (destinationType != 'SERVICE') {
        destinationHint = destinationType;
        destinationType = "CONTAINERVEHICLE";
      }
    }

    if (destinationType == 'SERVICE') {
      this.askShiftForWorkerToService(dailyWorker, destinationId, destinationHint);
      return;
    }

    var containerVehicle = this.state.dailyContainerVehicleStartingOnDate.find(x => x.containervehicle == destinationId);
    this.askShiftForWorkerToVehicle(dailyWorker, containerVehicle, destinationHint);

    //this.moveWorkerToVehicle(dailyWorker, containerVehicle);
    //this.setParent(over ? over.id : null);
  }

  private async loadDataForDay(targetDay: DateTime, keepData = false) {
    this.setState({
      fullData: keepData,
      loading: true,
      day: targetDay
    });


    const [
      dailyWorkers,
      activeWorkers,
      needs,
      shifts,
      allVehicles,
      vehicleEntries,
      /*planning, containerVehiclesYesterday, */ containerVehiclesToday,
      radioCodeIds,
      allNeedsVehicles,
    ] = await Promise.all<any>([
      new DailyPlanningApi().getWorkersByDate(targetDay, true),
      new WorkerRegistryApi().getAllActiveInDay(targetDay),
      new NeedManagementApi().getAll(), //.getAllActiveInDay(targetDay), USO ALL, PERCHE' POTREBBERO ESSERCI SERVIZI SPOSTATI
      new ShiftRegistryApi().getAll(),
      this.vehicleApi.getOrSearch('', 10000, 0),
      this.vehiclePlanningApi.getDailyPlanningVehicleByDay(targetDay),
      //this.monthlyPlanningApi.getByDate(targetDay.startOf('month')),
      //this.containerVehicleApi.getDistinctContainerVehiclesForDate(targetDay.minus({day: 1}).toFormat(GfDateUtils.STORED_DATE_FORMAT)),
      this.containerVehicleApi.getDistinctContainerVehiclesForDate(targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT)),
      this.radioCodeApi.getAllRadioCodes(), //.getRadioCodes(0, Number.MAX_SAFE_INTEGER),
      this.needApi.getAllNeedVehicles(),
    ]);
    this.setState(
      {
        day: targetDay,
        dailyWorkers,
        activeWorkers: activeWorkers.content,
        needs: this.getRelevantForDay(needs.content, targetDay),
        shifts: shifts.content,
        allVehicles: allVehicles.content,
        dailyVehicles: vehicleEntries,
        //monthlyPlanning: planning,
        containerServiceVehicle: containerVehiclesToday.content,
        // TODO: not check date but only previousDay bool
        dailyWorkersStartingOnDate: dailyWorkers.filter(it => it.date == targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT) && !it.previousDay),
        dailyVehiclesStartingOnDate: vehicleEntries.filter(it => it.date == targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT) && !it.previousDay),
        dailyContainerVehicleStartingOnDate: containerVehiclesToday.content.filter(it => it.date == targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT)),
        radioCodeIds: radioCodeIds.content,
        needVehicles: allNeedsVehicles.needVehicleDtoList,
      },
      () => {
        const radioCodesDto = radioCodeIds.content;
        const needVehicles = allNeedsVehicles.needVehicleDtoList;
        let radioMap = new Map();
        radioCodesDto.forEach(it => {
          radioMap.set(it.id, it.name);
        });
        let vehicleMap = new Map();
        needVehicles.forEach(it => {
          vehicleMap.set(it.id, it);
        });

        let shiftMap = new Map();
        shifts.content.forEach(it => {
          shiftMap.set(it.shiftCode, it);
        });

        let needMap = new Map();
        needs.content.forEach(it => {
          needMap.set(it.serviceCode, it);
        });

        const { dailyWorkers, workersByService } = this.recalculateWorkers(false, this.state.dailyWorkers, this.state.activeWorkers, shiftMap);

        this.setState({
          radioCodeMap: radioMap,
          vehiclesMap: vehicleMap,
          shiftMap,
          fullData: keepData || (this.state.workersMap.size > 0),
          dailyWorkersStartingOnDate: dailyWorkers,
          workersByService,
          needMap,
          loading: false,
        }, () => {
          this.loadAllWorkers(activeWorkers.content);
        });
      },
    );
  }

  private getRelevantForDay(content: NeedDto[], targetDay: DateTime) {
    return content.filter(need => {
      const endDate = DateTime.fromFormat(need.endDate, GfDateUtils.STORED_DATE_FORMAT);
      return endDate >= targetDay;
    });
  }

  private async loadAllWorkers(workers: WorkerDto[]) {
    let workersMap = new Map();
    workers.forEach(it => {
      workersMap.set(it.id, it);
    });

    const { dailyWorkers, workersByService } = this.recalculateWorkers(false, this.state.dailyWorkers, workers);

    this.setState({
      //allWorkers: workers.content,
      workersMap,
      dailyWorkersStartingOnDate: dailyWorkers,
      fullData: true, //dailyWorkers.length > 0,
      workersByService,
    });
  }

  fixDailyWorkersNames(workers: any[], activeWorkers: WorkerDto[]) {
    var dailyWorkers = workers.map(x => {
      var worker = activeWorkers.find(w => w.id == x.workerid);
      if (worker) {
        x.name = worker.lastname + ' ' + worker.name;
      }
      else if (x.workerid) {
        x.name = 'incompleto';
      }
      return x;
    })
    .filter (x => x.name != 'incompleto');

    return dailyWorkers;
  }
}
